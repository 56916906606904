export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
  },
  {
    key: 'description',
    subKey: 'itemNo',
    label: 'field.description',
  },
  {
    key: 'quantity',
    label: 'field.quantity',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'shippedQuantity',
    label: 'field.shippedQuantity',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'receivedQuantity',
    label: 'field.receivedQuantity',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
];
